<mpb-navigation></mpb-navigation>
<div [formGroup]="loginForm" class="login">
  <div class="login_img">
    <h1>Start discovering Master Polyglots today!!</h1>
    <div>
      <svg
        width="24px"
        height="24px"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#37ff8b"
      >
        <path
          d="M7 12.5l3 3 7-7"
          stroke="#37ff8b"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
          stroke="#37ff8b"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      Learn to code in a variety of languages.
    </div>
    <div>
      <svg
        width="24px"
        height="24px"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#37ff8b"
      >
        <path
          d="M7 12.5l3 3 7-7"
          stroke="#37ff8b"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
          stroke="#37ff8b"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      Get access to expert instructors.
    </div>
    <div>
      <svg
        width="24px"
        height="24px"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#37ff8b"
      >
        <path
          d="M7 12.5l3 3 7-7"
          stroke="#37ff8b"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
          stroke="#37ff8b"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      Start building your dream career today.
    </div>
    <div>
      <svg
        width="24px"
        height="24px"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#37ff8b"
      >
        <path
          d="M7 12.5l3 3 7-7"
          stroke="#37ff8b"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
          stroke="#37ff8b"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      Become the go-to person in your field.
    </div>
    <div class="login_img_container">
      <img
        class="lf-wide-img"
        src="https://mpb-site.s3.us-east-2.amazonaws.com/imgs/app-img-bk.png"
        alt=""
      />
      <img
        class="lf-bt-wide-img"
        src="https://mpb-site.s3.us-east-2.amazonaws.com/imgs/black-women-code.jpeg"
        alt=""
      />
      <img
        class="long-img"
        src="https://mpb-site.s3.us-east-2.amazonaws.com/imgs/code-on-phone.jpeg"
        alt=""
      />
      <img
        class="md-wide-img"
        src="https://mpb-site.s3.us-east-2.amazonaws.com/imgs/coding-bk.jpeg"
        alt=""
      />
    </div>
  </div>
  <form class="login_content">
    <h1>Student Portal Login</h1>
    <input formControlName="email" type="email" placeholder="Email" />
    <input formControlName="password" type="password" placeholder="Password" />
    <button type="submit" (click)="login()">Login</button>
    <div>or</div>
    <div class="login_request">
      Request student access <span class="login_here">here.</span>
    </div>
  </form>
</div>
<mpb-footer></mpb-footer>
