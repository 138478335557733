<div class="welcome">
  <div class="welcome_overlay"></div>
  <div class="welcome_vid">
    <video
      autoplay="true"
      [muted]="true"
      [loop]="true"
      src="https://mpb-site.s3.us-east-2.amazonaws.com/vids/young-girl-code.mp4"
    ></video>
  </div>
  <div class="welcome_img">
    <img
      class="welcome_circle"
      src="https://mpb-site.s3.us-east-2.amazonaws.com/imgs/mp-official.png"
      alt=""
    />
    <button (click)="routeTo('home')">
      <span></span>
      <span></span>
      <span></span>
      <span></span> Enter
    </button>
  </div>
</div>
