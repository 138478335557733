import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'mpb-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  infoFormGroup = this._formBuilder.group({
    name: '',
    email: '',
    msg: '',
  });

  constructor(
    private appService: AppService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.appService.triggerModal(false);
  }

  sendContact() {
    this.appService
      .postData('/api/contact', this.infoFormGroup.value)
      .subscribe((res: any) => {
        if (res.sentSuccessfully) {
          this.infoFormGroup.reset();
        }
      });
  }
}
