import { Component } from '@angular/core';

@Component({
  selector: 'mpb-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent {

}
