<mpb-navigation></mpb-navigation>
<div class="home">
  <div *ngIf="isShowModal" (click)="dismissModal()" class="home_overlay"></div>
  <div class="home_banner">
    <div class="home_banner_vid">
      <div class="home_banner_overlay"></div>
      <video
        autoplay="true"
        [muted]="true"
        [loop]="true"
        [src]="bgVideo"
      ></video>
    </div>
    <div class="home_banner_headline">
      Learn the secrets of programming by mastering the patterns. Become an
      Engineer who can code in any language
      <button class="learn-more">
        <span class="circle" aria-hidden="true">
          <span class="icon arrow"></span>
        </span>
        <span (click)="routeTo('bootcamp')" class="button-text"
          >Get Started Now</span
        >
      </button>
    </div>
  </div>
  <div class="home_program-languages">
    <img
      class="home_program-languages_ic"
      *ngFor="let lang of languages"
      [src]="lang"
      alt=""
    />
  </div>
  <mpb-featured-courses
    [featuredCourses]="homeCourses"
    [infoContent]="infoContent"
  ></mpb-featured-courses>
  <mpb-featured-bootcamp></mpb-featured-bootcamp>
</div>
<mpb-footer></mpb-footer>
