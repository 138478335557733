/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { MatTableDataSource } from '@angular/material/table';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {MatNativeDateModule} from '@angular/material/core';

interface attendance {
  name: string;
  week: number;
  present: string;
}

interface assignments {
  title: string;
  dueDate: string;
}

@Component({
  selector: 'mpb-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})


export class DashboardComponent implements OnInit {
  // id= 'tsparticles';

  // /* Starting from 1.19.0 you can use a remote url (AJAX request) to a JSON with the configuration */
  // particlesUrl = 'http://foo.bar/particles.json';

  // /* or the classic JavaScript object */
  // particlesOptions = {
  //   background: {
  //     color: {
  //       value: '',
  //     },
  //   },
  //   fpsLimit: 120,
  //   interactivity: {
  //     events: {
  //       onClick: {
  //         enable: true,
  //         mode: ClickMode.push,
  //       },
  //       onHover: {
  //         enable: true,
  //         mode: HoverMode.repulse,
  //       },
  //       resize: true,
  //     },
  //     modes: {
  //       push: {
  //         quantity: 4,
  //       },
  //       repulse: {
  //         distance: 200,
  //         duration: 0.4,
  //       },
  //     },
  //   },
  //   particles: {
  //     color: {
  //       value: '#37ff8b',
  //     },
  //     links: {
  //       color: '#ffffff',
  //       distance: 150,
  //       enable: true,
  //       opacity: 0.2,
  //       width: 1,
  //     },
  //     collisions: {
  //       enable: true,
  //     },
  //     move: {
  //       direction: MoveDirection.none,
  //       enable: true,
  //       outModes: {
  //         default: OutMode.bounce,
  //       },
  //       random: false,
  //       speed: 1,
  //       straight: false,
  //     },
  //     number: {
  //       density: {
  //         enable: true,
  //         area: 800,
  //       },
  //       value: 50,
  //     },
  //     opacity: {
  //       value: 0.5,
  //     },
  //     shape: {
  //       type: 'circle',
  //     },
  //     size: {
  //       value: { min: 1, max: 5 },
  //     },
  //   },
  //   detectRetina: true,
  // };
selected: any;
  
  openmodal() {
  throw new Error('Method not implemented.');
  }
  public dashboard: any = '';
  public assignments: any = '';
  public materials: any = '';
  public attendance: any = '';
  public user: any = '';
  public isLoading = true;
  attendanceDisplayedColumns: string[] = ['week', 'name', 'present'];
  assignmentsDisplayedColumns: string[] = ['title', 'dueDate']
  router: any;
  attendanceDataSource: any; 
  assignmentsDataSource: any; 
  
  date: Date | undefined;

  constructor(private appService: AppService) {}

  

  ngOnInit(): void {
    this.appService.getUser().subscribe((u: any) => {
      console.log('user', u);
      this.user = u.user;
    });

    this.getData('/api/dashboard').subscribe((res) => {
      console.log('res', res);
      this.dashboard = res.dashboard;
      this.assignments = res.assignments;
      this.materials = res.materials;
      this.attendance = res.attendance;
      this.attendanceDataSource = new MatTableDataSource(res.attendance);
      this.assignmentsDataSource = new MatTableDataSource(res.assignments);
      this.date = new Date();
      this.date.setDate( this.date.getDate())
    });

    this.isLoading = false;
  }
  routeTo(route: string) {
    this.appService.triggerLoader(true);
    this.router.navigate([route]);
  }
  private getData(forUrl: string): Observable<any> {
    return this.appService.getData(forUrl);
  }

  // particlesLoaded(container: any): void {}

  // async particlesInit(engine: any): Promise<void> {
  //   // Starting from 1.19.0 you can add custom presets or shape here, using the current tsParticles instance (main)
  //   // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //   // starting from v2 you can add only the features you need reducing the bundle size
  //   await loadFull(engine);
  // }
}
export class DatepickerInlineCalendarExample {
  selected: Date | null | undefined;
}
