<div class="loader-container">
  <div class="loader">
    <div class="face">
      <div class="circle"></div>
    </div>
    <div class="face">
      <div class="circle"></div>
    </div>
    <div class="please">Coding... please wait!!</div>
  </div>
</div>
