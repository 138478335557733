import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { FeaturedCoursesModalComponent } from './featured-courses-modal/featured-courses-modal.component';

@Component({
  selector: 'mpb-featured-courses',
  templateUrl: './featured-courses.component.html',
  styleUrls: ['./featured-courses.component.scss'],
})
export class FeaturedCoursesComponent implements OnInit {
  @Input() featuredCourses: any;
  @Input() infoContent: any;
  @Input() hideSeeMore: any = true;
  public selectedId = null;
  public isShowModal: any;

  constructor(
    private appService: AppService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.appService.isShowingModal$.subscribe((show) => {
      this.isShowModal = show;
    });
    console.log('featured', this.featuredCourses);
  }

  async handleSeeMore(course) {
    console.log('this is the selected course', course)
    const dialogRef = this.dialog.open(FeaturedCoursesModalComponent, {
      data: {
        courseData: course,
        showBootcampInfo: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  handlePlay(vid: any, icon: Element) {
    icon.remove();
    vid.play();
    vid.setAttribute('controls', 'controls');
  }

  routeTo(r: any) {
    this.appService.routeTo(r);
  }

  showModal(row: any, idx: number) {
    console.log('modal', this.isShowModal);
    this.isShowModal = true;
    this.infoContent = this.featuredCourses.variables[row][idx];
    this.appService.triggerModal(true);
  }
}
