<mpb-navigation></mpb-navigation>
<div class="dashboard">
  <!-- <ng-particles
    [id]="id"
    [particlesInit]="particlesInit"
    (particlesLoaded)="particlesLoaded($event)"
  ></ng-particles> -->
  <div id="tsparticles"></div>
  <div class="dashboard__left-nav_container">
    <div class="dashboard__lft-nav">
      <div class="dashboard__lft-nav_head">Dashboard</div>

      <div class="dashboard__lft-nav_menu">
        <div class="dashboard__lft-nav_menu_item">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#000000"
          >
            <path
              d="M10 18v-3a2 2 0 012-2v0a2 2 0 012 2v3M2 8l9.732-4.866a.6.6 0 01.536 0L22 8"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M20 11v8a2 2 0 01-2 2H6a2 2 0 01-2-2v-8"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          <a routerLink="/material">Material</a>
        </div>
        <div class="dashboard__lft-nav_menu_item">
          <svg
            width="24px"
            stroke-width="1.5"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#000000"
          >
            <path
              d="M4 19V5a2 2 0 012-2h13.4a.6.6 0 01.6.6v13.114M6 17h14M6 21h14"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
            <path
              d="M6 21a2 2 0 110-4"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M9 7h6"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
          </svg>
          <a routerLink="/grades">Grades</a>
        </div>

        <div class="dashboard__lft-nav_menu_item">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#000000"
          >
            <path
              d="M9 5h12M5 7V3L3.5 4.5M5.5 14h-2l1.905-2.963a.428.428 0 00.072-.323C5.42 10.456 5.216 10 4.5 10c-1 0-1 .889-1 .889s0 0 0 0v.222M4 19h.5a1 1 0 011 1v0a1 1 0 01-1 1h-1M3.5 17h2L4 19M9 12h12M9 19h12"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          <a routerLink="/assignments">Assignments</a>
        </div>

        <div class="dashboard__lft-nav_menu_item">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#000000"
          >
            <path
              d="M14.149 9.472v-5.86c0-.89-.722-1.612-1.612-1.612v0c-.89 0-1.611.722-1.611 1.612v4.834"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
            <path
              d="M16.346 12.841l2.176-7.252a1.584 1.584 0 00-1.083-1.98v0a1.585 1.585 0 00-1.961 1.098l-1.33 4.764M7.62 9.25l1.055 2.341a1.612 1.612 0 01-2.938 1.325L4.68 10.575A1.612 1.612 0 017.62 9.25z"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
            <path
              d="M11.72 12.261v0a2.322 2.322 0 00-.068-1.742l-1.073-2.38a1.584 1.584 0 00-2.101-.79v0a1.584 1.584 0 00-.764 2.14l.135.276"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
            <path
              d="M13.857 17.677l.492-.984a.176.176 0 00-.108-.248l-3.55-1.044a1.537 1.537 0 01-1.095-1.635v0a1.537 1.537 0 011.67-1.37l4.788.446s3.81.586 2.49 4.395c-1.318 3.81-1.757 5.128-4.687 5.128H8.876a4.249 4.249 0 01-4.249-4.249v0L4.48 9.912"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
          </svg>
          <a routerLink="/attendance">Attendance</a>
        </div>
      </div>

      <!-- <div class="dashboard__lft-nav_settings">
        <div class="dashboard__lft-nav_head">Settings</div>
      </div> -->
    </div>
    <div class="dashboard__lft-nav_menu_attendance">
      <h1>Attendance</h1>
      <mat-table
        class="attendance-table"
        #table
        [dataSource]="attendanceDataSource"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell
            class="attendance-header-row"
            *matHeaderCellDef
            style="font-family: Satoshi-Black"
          >
            Name
          </mat-header-cell>
          <mat-cell
            class="attendance-column"
            *matCellDef="let user"
            style="font-family: Satoshi-Black"
          >
            {{ user.name }}
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="present">
          <mat-header-cell
            class="attendance-header-row"
            *matHeaderCellDef
            style="font-family: Satoshi-Black"
          >
            Present
          </mat-header-cell>
          <mat-cell
            class="attendance-column"
            *matCellDef="let user"
            style="font-family: Satoshi-Black"
          >
            {{ user.present }}
          </mat-cell>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="week">
          <mat-header-cell
            class="attendance-header-row"
            *matHeaderCellDef
            style="font-family: Satoshi-Black"
          >
            Week
          </mat-header-cell>
          <mat-cell
            class="attendance-column"
            *matCellDef="let user"
            style="font-family: Satoshi-Black"
          >
            {{ user.week }}
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="attendanceDisplayedColumns"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: attendanceDisplayedColumns"
        ></mat-row>
      </mat-table>
    </div>
  </div>

  <div class="dashboard__ctr-content">
    <div class="dashboard__ctr-content_profile-bar">
      <div class="dashboard__ctr-content_profile-bar_info">
        <div class="dashboard__ctr-content_profile-bar_info_img">
          <img [src]="user?.profileImg" alt="" />
        </div>
        <div class="dashboard__ctr-content_profile-bar_info_name">
          <span class="dashboard__ctr-content_profile-bar_info_name_head"
            >{{ user?.firstname }} {{ user?.lastname }}</span
          >
          <span class="dashboard__ctr-content_profile-bar_info_name_subhead"
            >Student</span
          >
        </div>
        <div class="dashboard__ctr-content_profile-bar_info_container">
          <div class="dashboard__ctr-content_profile-bar_info_container_date">
            <!-- <mat-card class="demo-inline-calendar-card">
              <mat-calendar [(selected)]="selected"></mat-calendar>
            </mat-card>
            <p>Selected date: {{selected}}</p> -->
            
              <svg
                width="24px"
                height="24px"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color="#000000"
              >
                <path
                  d="M15 4V2m0 2v2m0-2h-4.5M3 10v9a2 2 0 002 2h14a2 2 0 002-2v-9H3zM3 10V6a2 2 0 012-2h2M7 2v4M21 10V6a2 2 0 00-2-2h-.5"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <span>{{ date | date }}</span>
            
          </div>
          <div class="dashboard__ctr-content_profile-bar_info_container_search">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#000000"
            >
              <path
                d="M17 17l4 4M3 11a8 8 0 1016 0 8 8 0 00-16 0z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <input class="search_input" type="text" placeholder="Search.."
            
            >
          </div>

          <div
            class="dashboard__ctr-content_profile-bar_info_container_notifications"
          >
            <svg
              width="24px"
              height="24px"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#000000"
            >
              <path
                d="M18.134 11C18.715 16.375 21 18 21 18H3s3-2.133 3-9.6c0-1.697.632-3.325 1.757-4.525C8.883 2.675 10.41 2 12 2c.337 0 .672.03 1 .09M19 8a3 3 0 100-6 3 3 0 000 6zM13.73 21a1.999 1.999 0 01-3.46 0"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <div
            class="dashboard__ctr-content_profile-bar_info_container_messages"
          >
            <svg
              width="24px"
              height="24px"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#000000"
            >
              <path
                d="M7 12h10M7 8h6"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M3 20.29V5a2 2 0 012-2h14a2 2 0 012 2v10a2 2 0 01-2 2H7.961a2 2 0 00-1.561.75l-2.331 2.914A.6.6 0 013 20.29z"
                stroke="#000000"
                stroke-width="1.5"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard__ctr-content_profile-section">
      <div class="dashboard__ctr-content_profile-section_middle">
        <div class="dashboard__ctr-content_profile-section_grades">
          <h1>Grade</h1>
          <div class="your-grade">{{ user?.grade }}</div>
        </div>
        <div class="dashboard__ctr-content_profile-section_Assignment">
          <h1>Assignments</h1>
          <mat-table
            class="assignment-table"
            #table
            [dataSource]="assignmentsDataSource"
          >
            <!-- Position Column -->
            <ng-container matColumnDef="title">
              <mat-header-cell
                class="assignment-header-row"
                *matHeaderCellDef
                style="font-family: Satoshi-Black"
              >
                Title
              </mat-header-cell>
              <mat-cell
                class="assignment-row"
                *matCellDef="let user"
                style="font-family: Satoshi-Black"
              >
                {{ user.title }}
              </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="dueDate">
              <mat-header-cell
                class="assignment-header-row"
                *matHeaderCellDef
                style="font-family: Satoshi-Black"
              >
                Due Date
              </mat-header-cell>
              <mat-cell
                class="assignment-row"
                *matCellDef="let user"
                style="font-family: Satoshi-Black"
              >
                {{ user.dueDate }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="assignmentsDisplayedColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: assignmentsDisplayedColumns"
            ></mat-row>
          </mat-table>
          <!-- <ul>
            <li *ngFor="let assignment of assignments; let i = index">
              <div class="assignment-title">{{ assignment.title }}</div>
              <div class="assignment-dueDate">Due: {{ assignment.dueDate }}</div>
              <img src="{{ assignment.badge}}"> This is going to display once assignment is complete-->
          <!-- <img src="{{ assignment.img}}"> The image will be displayed on actual assignment detail page-->
          <!-- </li> -->
          <!-- </ul> -->
        </div>
      </div>

      <div class="dashboard__ctr-content_profile-section_info">
        <div class="dashboard__ctr-content_profile-section_info_lessons">
          <div class="material-header">
            <h1>Material</h1>
          </div>
          <ol *ngFor="let material of materials; let i = index">
            <div class="material-name">{{ material.title }}</div>

            <div
              *ngFor="let slides of material.slides; let j = index"
              class="material-link"
            >
              <a [href]="slides.link">{{ slides.name }}</a>
            </div>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<mpb-footer></mpb-footer>
<mpb-loader *ngIf="isLoading"></mpb-loader>
