import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { BootcampModalComponent } from './bootcamp-modal/bootcamp-modal.component';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'mpb-bootcamp',
  templateUrl: './bootcamp.component.html',
  styleUrls: ['./bootcamp.component.scss'],
})
export class BootcampComponent implements OnInit {
  public isSignupBtnHidden = false;
  public inView = false;
  public isReady = false;
  public isShowModal = false;
  public isMobile = false;
  public wind: any = window;
  count_particles: any;
  stats: any;
  update: any;
  pageData: any;
  bootcampInfo: any;

  constructor(
    private router: Router,
    private appService: AppService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.appService.triggerModal(false);
    const el = document.getElementById('works');

    window.addEventListener('scroll', () => {
      this.inView = this.appService.handleScrollAnimation(el);
    });

    this.appService.getData('/api/bootcamp').subscribe((res: any) => {
      this.pageData = res;
      console.log('res from bootcamp', res);
    });

    this.appService.scrollToTop();
  }

  async handleBootcampSelection(id) {
    if (this.isMobile) {
      this.bootcampInfo = this.pageData?.bootcamps[id].priceInfo;
      this.router.navigate(['/bootcamp-modal'], { queryParams: { id: id } });
    } else {
      const dialogRef = this.dialog.open(BootcampModalComponent, {
        data: {
          bootcampInfo: this.pageData?.bootcamps[id].priceInfo,
          showBootcampInfo: true,
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
      });
    }
  }

  dismissModal() {
    this.appService.triggerModal(false);
  }
}
