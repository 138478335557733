import { Component } from '@angular/core';

@Component({
  selector: 'mpb-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent {

}
