<mpb-navigation></mpb-navigation>
<div class="contact">
  <div class="contact_content">
    <div class="contact_content_description">
      <mat-stepper #stepper>
        <mat-step [stepControl]="infoFormGroup" label="Contact Us">
          <h1>Tell us what's on your mind ?</h1>
          <div class="cards-container">
            <form [formGroup]="infoFormGroup">
              <ng-template matStepLabel>Contact Us</ng-template>
              <mat-form-field appearance="fill">
                <div class="form-contact">
                  <div class="wrapper">
                    <input
                      type="text"
                      formControlName="name"
                      placeholder="Full Name"
                      required
                    />
                    <div class="validation">Required</div>
                  </div>
                  <div class="wrapper">
                    <input
                      type="email"
                      formControlName="email"
                      placeholder="Email"
                      required
                    />
                    <div class="validation">Required</div>
                  </div>
                  <textarea
                    formControlName="msg"
                    id=""
                    cols="70"
                    rows="10"
                  ></textarea>
                </div>
              </mat-form-field>
              <div>
                <button (click)="sendContact()" mat-button>Send</button>
              </div>
            </form>
            <div class="form-img-container">
              <img
                src="https://mpb-site.s3.us-east-2.amazonaws.com/imgs/bootcamp/coffee-shop-coding.jpeg"
                alt=""
              />
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
<mpb-footer></mpb-footer>
