import { Component, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'mpb-featured-bootcamp',
  templateUrl: './featured-bootcamp.component.html',
  styleUrls: ['./featured-bootcamp.component.scss'],
})
export class FeaturedBootcampComponent implements OnInit {
  @Input() hideSignupBtn = true;
  bootCampImgs: any;
  currentImgId: any = 0;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getData('/api/home').subscribe((res: any) => {
      if (res) {
        this.bootCampImgs = res.bootcampImgs;
      }
    });
    this.startImgCarousel();
  }

  startImgCarousel() {
    let count = 0;
    timer(10000, 8000).subscribe(() => {
      if (count <= this.bootCampImgs.length) {
        this.currentImgId = count;
        count++;
      } else {
        count = 0;
        this.currentImgId = count;
      }
    });
  }

  routeTo(r: any) {
    this.appService.routeTo(r);
  }
}
