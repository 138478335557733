import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../services/app.service';

@Component({
  selector: 'mpb-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  constructor(private router: Router, private appService: AppService) {}

  ngOnInit(): void {}

  routeTo(route: string) {
    this.appService.triggerLoader(true);
    this.router.navigate([route]);
  }
}
