<div class="bootcamp-p">
  <div class="animation-wrapper">
    <div class="particle particle-1"></div>
    <div class="particle particle-2"></div>
    <div class="particle particle-3"></div>
    <div class="particle particle-4"></div>
  </div>
  <div *ngIf="isReady" class="bootcamp-p-overlay"></div>
  <mpb-navigation></mpb-navigation>
  <mpb-featured-bootcamp
    [hideSignupBtn]="isSignupBtnHidden"
  ></mpb-featured-bootcamp>

  <section id="works" class="section">
    <h1>{{ pageData?.howItWorks?.title }}</h1>
    <div class="section__content">
      <p>{{ pageData?.howItWorks?.top }}</p>
      <p>{{ pageData?.howItWorks?.middle }}</p>
      <p>{{ pageData?.howItWorks?.bottom }}</p>
    </div>
  </section>
  <section id="get" class="section">
    <h2>{{ pageData?.whatYouReceive?.title }}</h2>
    <div class="section__content">
      <p>{{ pageData?.whatYouReceive?.top }}</p>
      <p>{{ pageData?.whatYouReceive?.bottom }}</p>
    </div>
  </section>
  <section id="learn" class="section">
    <div class="section__card-container">
      <div *ngFor="let card of pageData?.bootcamps; index as i" class="card">
        <img class="card_img" [src]="card?.imageUrl" alt="" />
        <div class="card_content">
          <img class="card_content__img" [src]="card?.iconUrl" alt="" />
          <div class="card_content__title">
            <p
              class="card_content__title__head"
              [ngStyle]="{ color: card?.color }"
            >
              {{ card?.title }}
            </p>
            <p class="card_content__title__desc">
              {{ card?.subTitle }}
            </p>
          </div>
          <button
            (click)="handleBootcampSelection(i)"
            class="card_content__btn"
            [ngStyle]="{ 'background-color': card?.color }"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <h3>Select One To Begin</h3>
  </section>
  <mpb-more-info
    *ngIf="isShowModal"
    [info]="bootcampInfo"
    [showBootcampInfo]="isShowModal"
  ></mpb-more-info>
  <mpb-footer></mpb-footer>
</div>
