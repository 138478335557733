import { Component, Inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'mpb-featured-courses-modal',
  templateUrl: './featured-courses-modal.component.html',
  styleUrls: ['./featured-courses-modal.component.scss']
})
export class FeaturedCoursesModalComponent {
  public course: any;

  constructor(
    private appService: AppService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.course = data.courseData;
    console.log('featured courses dialog', data)

  }

  handlePlay(vid: any, icon: Element) {
    icon.remove();
    vid.play();
    vid.setAttribute('controls', 'controls');
  }

  dismissModal() {
    this.dialogRef.close()
  }

  routeTo(r: any) {
    this.appService.routeTo(r);
  }
}
