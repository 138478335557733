import { Component } from '@angular/core';

@Component({
  selector: 'mpb-grades',
  templateUrl: './grades.component.html',
  styleUrls: ['./grades.component.scss']
})
export class GradesComponent {

}
