import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';

@Component({
  selector: 'mpb-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  public homeCourses: any;
  public infoContent: any;
  public hideSeeMoreBtn = false;
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.triggerModal(false);
    this.appService.scrollToTop();
    this.appService.getData('/api/home').subscribe((res: any) => {
      if (res) {
        this.homeCourses = res.homeCourses;

        this.appService.triggerLoader(false);
        this.appService.dataReady$.next({
          bootcamp: res.bootcampImgs,
        });
      }
    });
  }
}
