import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'mpb-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public isToggleMenu = false;
  public isLoggedIn = false;
  public user: any;
  public loggedInMenu = [
    {
      name: 'Dashboard',
      link: 'dashboard',
    },
    {
      name: 'Grades',
      link: 'grades',
    },
    {
      name: 'Assignments',
      link: 'assignments',
    },
    {
      name: 'Material',
      link: 'course-material',
    },
    {
      name: 'Logout',
      link: 'logout',
    },
  ];

  public loggedOutMenu = [
    {
      name: 'Home',
      link: 'home',
    },
    {
      name: 'Courses',
      link: 'courses',
    },
    {
      name: 'Bootcamp',
      link: 'bootcamp',
    },
    {
      name: 'Contact',
      link: 'contact',
    },
    {
      name: 'Login',
      link: 'login',
    },
  ];

  public response: any = {
    failure: () => {
      this.appService.deleteCookie('mpb-session');
      this.isLoggedIn = false;
      this.routeTo('/home');
      this.appService.showToast('Please sign back in !!!', 'warning');
    },
    success: (res: any) => {
      this.user = res.user;
    },
  };

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    const cookie = this.appService.getCookie('mpb-session');

    if (cookie) {
      this.isLoggedIn = true;
      this.appService
        .getData('/api/auth/session', {
          authorization: `Bearer ${
            this.appService.getCookie('mpb-session') || ''
          }`,
        })
        .subscribe((res: any) => {
          if (res.errors) this.response['failure']();
          if (!res.errors && res.user) this.response['success'](res);
        });
    }
  }

  routeTo(r: any) {
    if (r === 'logout') {
      this.appService.deleteCookie('mpb-session');
      this.appService.routeTo(r);
    } else {
      this.appService.routeTo(r);
    }
  }

  showMenu() {
    this.isToggleMenu = true
    console.log('menus showing is', this.isToggleMenu)
  }

  closeMenu() {
    this.isToggleMenu = false;
  }
}
