import { Component } from '@angular/core';

@Component({
  selector: 'mpb-graduation',
  templateUrl: './graduation.component.html',
  styleUrls: ['./graduation.component.scss']
})
export class GraduationComponent {

}
