import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'mpb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public year = '';
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getData('/api/footer').subscribe((d: any) => {
      this.year = d.year;
    });
  }

  routeTo(r: any) {
    this.appService.routeTo(r);
  }

  socialMedia(u) {
    window.open(u, '_blank');
  }
}
