/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isLoading$: BehaviorSubject<any> = new BehaviorSubject('');
  public isShowingModal$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public user$: BehaviorSubject<any> = new BehaviorSubject({});
  public dataReady$: BehaviorSubject<any> = new BehaviorSubject('');
  public toastr: BehaviorSubject<any> = new BehaviorSubject('');
  public shouldAnimate = false;
  public formBuilder: FormBuilder = new FormBuilder();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {}

  /*
   * Build Form
   */

  public buildForm(fields: any): FormGroup {
    const form: any = {};

    Object.keys(fields).map((key) => {
      form[key] = this.formBuilder.control(fields[key], [], []);
    });

    return this.formBuilder.group(form);
  }

  /*
   * @Get User
   */

  public getUser() {
    const headers = new HttpHeaders({
      authorization: `Bearer ${this.cookieService.get('mpb-session') || ''}`,
    });
    return this.httpClient.get('/api/auth/session', {
      headers: headers,
    });
  }

  public retrieve(url: string) {
    return this.httpClient
      .get(url)
      .pipe(catchError(async (err) => console.log('Error in api', err)));
  }

  /*
   * @Get Request
   */

  public getData(r: any, options?: any) {
    const headers = new HttpHeaders({
      ...options,
    });
    return this.httpClient.get(r, {
      headers: headers,
    });
  }

  /*
   * @Post Request
   */

  public postData(r: any, data: any, options?: any) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      ...options,
    });
    return this.httpClient.post(r, data, {
      headers: headers,
    });
  }

  /*
   * Delete Cookie
   */
  public deleteCookie(n: any): void {
    this.cookieService.delete(n);
    this.user$.next({
      isLoggedIn: false,
    });
  }

  /*
   * Set Cookie
   */
  public setCookie(n: any, v: any): void {
    this.cookieService.set(n, v);
    this.user$.next({
      isLoggedIn: true,
    });
  }

  public showToast(msg: string, type: string) {
    const toaster: any = {
      success: (msg: string) => {
        this.toastr.next({
          msg,
          show: true,
        });
      },
      error: (msg: string) => {
        this.toastr.next({
          msg,
          show: true,
        });
      },
    };
    toaster[type](msg);
  }

  /*
   * Get Cookie
   */
  public getCookie(n: any): any {
    return this.cookieService.get(n);
  }

  /*
   * Trigger Loader
   */

  public triggerLoader(showLoader: boolean) {
    this.isLoading$.next(showLoader);
  }

  /*
   * Trigger Modal
   */

  public triggerModal(showModal: boolean) {
    this.isShowingModal$.next(showModal);
  }

  /*
   * Route To
   */

  public routeTo(r: any) {
    this.router.navigate([r]);
  }

  /*
   * Scroll To Top
   */

  public scrollToTop() {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  /*
   * Element In/Out View
   */

  public elementInView(el: any, percentageScroll = 1) {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) /
        percentageScroll
    );
  }

  public elementOutofView(el: any) {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop > (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  /*
   * Scroll Animation
   */

  public handleScrollAnimation(currentEl: any) {
    if (currentEl) {
      if (this.elementInView(currentEl, 1.25)) {
        this.shouldAnimate = true;
      }

      if (this.elementOutofView(currentEl)) {
        this.shouldAnimate = false;
      }
    }

    return this.shouldAnimate;
  }
}
