import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BootcampComponent } from './bootcamp/bootcamp.component';
import { ContactComponent } from './contact/contact.component';
import { CoursesComponent } from './courses/courses.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GraduationComponent } from './graduation/graduation.component';
import { HomeComponent } from './home/home.component';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import { PricingComponent } from './pricing/pricing.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FeaturedBootcampComponent } from './components/featured-bootcamp/featured-bootcamp.component';
import { FeaturedCoursesComponent } from './components/featured-courses/featured-courses.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MoreInfoComponent } from './components/more-info/more-info.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SafePipe } from './pipes/safe.pipe';
import { BannerComponent } from './components/banner/banner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { GradesComponent } from './grades/grades.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialComponent } from './material/material.component';
import {MatStepperModule} from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { BootcampModalComponent } from './bootcamp/bootcamp-modal/bootcamp-modal.component';
import { FeaturedCoursesModalComponent } from './components/featured-courses/featured-courses-modal/featured-courses-modal.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    AppComponent,
    BootcampComponent,
    ContactComponent,
    CoursesComponent,
    DashboardComponent,
    GraduationComponent,
    HomeComponent,
    LoginSignupComponent,
    PricingComponent,
    WelcomeComponent,
    FeaturedBootcampComponent,
    FeaturedCoursesComponent,
    FooterComponent,
    LoaderComponent,
    MoreInfoComponent,
    NavigationComponent,
    SafePipe,
    BannerComponent,
    GradesComponent,
    AssignmentsComponent,
    AttendanceComponent,
    MaterialComponent,
    BootcampModalComponent,
    FeaturedCoursesModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableModule,
    MatStepperModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
}
}
