import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  faCircle,
  faCircleHalfStroke,
  faCircleNotch,
  faDesktop,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';
import { faUncharted } from '@fortawesome/free-brands-svg-icons';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'mpb-bootcamp-modal',
  templateUrl: './bootcamp-modal.component.html',
  styleUrls: ['./bootcamp-modal.component.scss'],
})
export class BootcampModalComponent implements OnInit {
  camp: any;
  faCircle = faCircle;
  faCircleHalfStroke = faCircleHalfStroke;
  faCircleNotch = faCircleNotch;
  faDesktop = faDesktop;
  faMobile = faMobile;
  faUncharted = faUncharted;

  bootcampForm!: FormGroup;
  paymentForm!: FormGroup;
  paymentInfo: any;
  userSelectedCamp: any;
  userSelectedDifficulty: any;
  showLevelError = false;
  showTypeError = false;
  isMobile = false;
  isLinear = false;
  bootcampId;

  orientation: StepperOrientation = 'vertical'

  constructor(
    private appService: AppService,
    public dialogRef: MatDialogRef<any>,
    public router: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.isMobile = this.deviceService.isMobile();
    
    if (this.isMobile) {
      this.orientation = 'vertical'
      this.router.queryParams.subscribe((r: any) => {
        this.bootcampId = r.id;
      })
  
      this.appService.getData('/api/bootcamp').subscribe((res: any) => {
        if (this.bootcampId) {
          this.camp = res.bootcamps[this.bootcampId].priceInfo
        }
      });
    } else {
      this.orientation = 'horizontal'
      this.camp = data.bootcampInfo;
    }
  }

  ngOnInit(): void {
    this.bootcampForm = this.appService.buildForm({
      bootcampLevel: '',
      bootcampType: '',
    });

    this.paymentForm = this.appService.buildForm({
      secondCtrl: '',
    });
  }

  checkStep() {
    return (
      this.bootcampForm.value['bootcampLevel'] !== '' &&
      this.bootcampForm.value['bootcampType'] !== ''
    );
  }

  handlePrevious(stepper: MatStepper) {
    stepper.previous();
  }

  handleNext(stepper: MatStepper) {
    if (
      this.bootcampForm.value['bootcampLevel'] === '' &&
      this.bootcampForm.value['bootcampType'] === ''
    ) {
      this.showLevelError = true;
      this.showTypeError = true;
      document
        .getElementById('difficultyLevel')
        ?.scrollIntoView({ behavior: 'smooth' });
    } else {
      stepper.next();
    }
  }

  handlePayment(u) {
    window.open(u, '_blank');
    console.log('payment form', this.paymentForm.value);
  }

  handleLevel(level) {
    console.log('leve', level);

    if (level === 'Beginner') {
      document.getElementById('Beginner')?.classList.add('selected');
      this.userSelectedDifficulty = level;
      this.bootcampForm['controls']['bootcampLevel'].setValue(level);
      this.showLevelError = false;
    } else {
      document.getElementById('Beginner')?.classList.remove('selected');
    }

    if (level === 'Intermediate') {
      document.getElementById('Intermediate')?.classList.add('selected');
      this.userSelectedDifficulty = level;
      this.bootcampForm['controls']['bootcampLevel'].setValue(level);
      this.showLevelError = false;
    } else {
      document.getElementById('Intermediate')?.classList.remove('selected');
    }

    if (level === 'Advanced') {
      document.getElementById('Advanced')?.classList.add('selected');
      this.userSelectedDifficulty = level;
      this.bootcampForm['controls']['bootcampLevel'].setValue(level);
      this.showLevelError = false;
    } else {
      document.getElementById('Advanced')?.classList.remove('selected');
    }
  }

  handleSelectedType(type) {
    this.userSelectedCamp = type;
    if (type === 'Frontend') {
      this.paymentInfo = this.camp?.twoMonth;
      document.getElementById('Frontend')?.classList.add('selected');
      this.bootcampForm['controls']['bootcampType'].setValue({
        type: type,
        camp: this.paymentInfo?.camp,
      });
      this.showTypeError = false;
    } else {
      document.getElementById('Frontend')?.classList.remove('selected');
    }

    if (type === 'Fullstack') {
      this.paymentInfo = this.camp?.threeMonth;
      document.getElementById('Fullstack')?.classList.add('selected');
      this.bootcampForm['controls']['bootcampType'].setValue({
        type: type,
        camp: this.paymentInfo?.camp,
      });
      this.showTypeError = false;
    } else {
      document.getElementById('Fullstack')?.classList.remove('selected');
    }

    if (type === '2-Month') {
      this.paymentInfo = this.camp?.twoMonth;
      document.getElementById('2-Month')?.classList.add('selected');
      this.bootcampForm['controls']['bootcampType'].setValue({
        type: type,
        camp: this.paymentInfo?.camp,
      });
      this.showTypeError = false;
    } else {
      document.getElementById('2-Month')?.classList.remove('selected');
    }

    if (type === '3-Month') {
      this.paymentInfo = this.camp?.threeMonth;
      document.getElementById('3-Month')?.classList.add('selected');
      this.bootcampForm['controls']['bootcampType'].setValue({
        type: type,
        camp: this.paymentInfo?.camp,
      });
      this.showTypeError = false;
    } else {
      document.getElementById('3-Month')?.classList.remove('selected');
    }
  }
}
