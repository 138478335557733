import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';

@Component({
  selector: 'mpb-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public languages: any[] = [];
  public homeCourses: any;
  public bootcamp: any[] = [];
  public infoContent: any;
  public bgVideo: any;
  public selectedId = null;
  public isShowModal = false;

  constructor(private appService: AppService) {
    this.appService.isLoading$.next(true);
  }

  ngOnInit(): void {
    this.appService.triggerLoader(true);
    this.appService.triggerModal(false);
    this.appService.scrollToTop();
    this.appService.getData('/api/home').subscribe((res: any) => {
      if (res) {
        this.bgVideo = res.bgVid;
        this.languages = res.programmingLanguages;
        this.homeCourses = res.homeCourses;
        this.bootcamp = res.bootcampImgs;
        this.appService.triggerLoader(false);
      }
    });
    this.appService.isShowingModal$.subscribe((show) => {
      this.isShowModal = show;
    });
  }

  dismissModal() {
    this.appService.triggerModal(false);
  }

  routeTo(r: any) {
    this.appService.routeTo(r);
  }
}
