<div class="navigation">
  <div *ngIf="isToggleMenu" class="navigation_overlay">
    <svg
      (click)="closeMenu()"
      width="46"
      height="46"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25Zm3.53 12.22a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 1.06-1.06L12 10.94l2.47-2.47a.75.75 0 0 1 1.06 1.06L13.06 12l2.47 2.47Z"
      ></path>
    </svg>
    <ng-container *ngIf="isLoggedIn">
      <ng-container *ngFor="let menu of loggedInMenu">
        <button (click)="routeTo(menu.link)" data-text="Awesome" class="button">
          <span class="actual-text">&nbsp;{{ menu.name }}&nbsp;</span>
          <span class="hover-text" aria-hidden="true"
            >&nbsp;{{ menu.name }}&nbsp;</span
          >
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isLoggedIn">
      <ng-container *ngFor="let menu of loggedOutMenu">
        <button (click)="routeTo(menu.link)" data-text="Awesome" class="button">
          <span class="actual-text">&nbsp;{{ menu.name }}&nbsp;</span>
          <span class="hover-text" aria-hidden="true"
            >&nbsp;{{ menu.name }}&nbsp;</span
          >
        </button>
      </ng-container>
    </ng-container>
  </div>
  <div class="navigation_logo">
    <img
      (click)="routeTo('home')"
      src="https://mpb-site.s3.us-east-2.amazonaws.com/imgs/mp-official.png"
      alt=""
    />
    <div>Master Polyglots</div>
    <div *ngIf="isLoggedIn" (click)="showMenu()" class="navigation_logo_ic">
      <svg
        width="46"
        height="46"
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25ZM9 15.727a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75v-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75Zm0-3.329a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75v-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75Zm0-3.375a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75v-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75Zm8.25 7.079H9.969v-1.5h7.281v1.5Zm0-3.329H9.969v-1.5h7.281v1.5Zm0-3.375H9.969v-1.5h7.281v1.5Z"
        ></path>
      </svg>
    </div>
    <div (click)="showMenu()" class="background">
      <button class="menu__icon">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </div>
  <div class="navigation_links">
    <button (click)="routeTo('courses')" data-text="Awesome" class="button">
      <span class="actual-text">&nbsp;Courses&nbsp;</span>
      <span class="hover-text" aria-hidden="true">&nbsp;Courses&nbsp;</span>
    </button>
    <button (click)="routeTo('bootcamp')" data-text="Awesome" class="button">
      <span class="actual-text">&nbsp;Bootcamp&nbsp;</span>
      <span class="hover-text" aria-hidden="true">&nbsp;Bootcamp&nbsp;</span>
    </button>
    <button
      *ngIf="isLoggedIn"
      (click)="routeTo('dashboard')"
      data-text="Awesome"
      class="button"
    >
      <span class="actual-text">&nbsp;Dashboard&nbsp;</span>
      <span class="hover-text" aria-hidden="true">&nbsp;Dashboard&nbsp;</span>
    </button>
    <button
      *ngIf="!isLoggedIn"
      (click)="routeTo('login')"
      data-text="Awesome"
      class="button"
    >
      <span class="actual-text">&nbsp;Login&nbsp;</span>
      <span class="hover-text" aria-hidden="true">&nbsp;Login&nbsp;</span>
    </button>

    <ng-container *ngIf="isLoggedIn">
      <div class="navigation_img-container">
        <img
          src="https://mpb-site.s3.us-east-2.amazonaws.com/imgs/black-women.jpeg"
          alt=""
        />
      </div>
      <h3>{{ user?.firstname }} {{ user?.lastname?.charAt(0) }}.</h3>
      <svg
        class="navigation_menu"
        (click)="showMenu()"
        width="24px"
        height="24px"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#f5f5f5"
      >
        <path
          d="M3 5h8M3 12h13M3 19h18"
          stroke="#f5f5f5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </ng-container>
  </div>
</div>
