import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'mpb-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnDestroy {
  public showToast = false;
  public message = '';
  public subscription: Subscription = new Subscription();
  constructor(private appService: AppService) {}

  ngOnInit() {
    this.subscription = this.appService.toastr.subscribe((toast) => {
      this.showToast = toast.show;
      this.message = toast.msg;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
