<div class="bootcamp">
  <div class="bootcamp_vid">
    <div class="bootcamp_overlay"></div>
    <video
      autoplay="true"
      [muted]="true"
      [loop]="true"
      src="https://mpb-site.s3.us-east-2.amazonaws.com/vids/code-typing.mp4"
    ></video>
  </div>
  <div class="bootcamp_content">
    <div class="bootcamp_content_title">
      Software Engineer Bootcamp
      <span class="bootcamp_content_title_span">.</span>
    </div>
    <ng-container *ngIf="hideSignupBtn">
      <button class="learn-more">
        <span class="circle" aria-hidden="true">
          <span class="icon arrow"></span>
        </span>
        <span (click)="routeTo('/bootcamp')" class="btn-text">Sign Up </span>
      </button>
    </ng-container>
    <div class="bootcamp_content_container">
      <div class="bootcamp_content_lf">
        <ng-container *ngFor="let bootcampImg of bootCampImgs; let idx = index">
          <h1 *ngIf="idx === currentImgId">"{{ bootcampImg.title }}"</h1>
          <h4 *ngIf="idx === currentImgId">
            {{ bootcampImg.subTitle }}
          </h4>
        </ng-container>
      </div>
      <div id="bootcampImg" class="bootcamp_content_img-container">
        <ng-container *ngFor="let bootcampImg of bootCampImgs; let i = index">
          <img *ngIf="i === currentImgId" [src]="bootcampImg.img" alt="" />
        </ng-container>
      </div>
    </div>
  </div>
</div>
