<div class="featured_courses">
  <div class="featured_courses_content">
    <div class="featured_courses_content_title">
      Featured Courses
      <span class="featured_courses_content_title_span">.</span>
    </div>
    <div class="featured_courses_content_main">
      When it comes to learning programming languages, there are a few things
      you should keep in mind. First, it's important to choose a language that
      is popular and in demand. This way, you'll be able to find plenty of
      resources and support as you learn. Second, it's important to choose a
      language that is relatively easy to learn. This way, you can avoid getting
      frustrated and giving up before you've even really started. Finally, it's
      important to find a course that is comprehensive and covers all the
      basics. Once you have these three things sorted out, you'll be well on
      your way to becoming a programming pro!
    </div>
  </div>
  <div *ngIf="featuredCourses" class="featured_courses_row">
    <div
      *ngFor="
        let courses of featuredCourses?.variables?.secondRow;
        let i = index
      "
      class="featured_card"
    >
      <div class="featured_card_fig">
        <video #coursesVid [src]="courses?.vid"></video>
        <svg
          #coursesPlayIcon
          (click)="handlePlay(coursesVid, coursesPlayIcon)"
          width="46"
          height="46"
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25Zm3.505 10.186-5.365 3.24a.506.506 0 0 1-.765-.435V8.76a.505.505 0 0 1 .765-.437l5.365 3.241a.51.51 0 0 1 0 .872Z"
          ></path>
        </svg>
      </div>

      <div class="featured_card_info">
        <h4>{{ courses?.subTitle }}</h4>
        <div>{{ courses?.moreInfo }}</div>
        <h2 (click)="handleSeeMore(courses)">See More</h2>
      </div>
    </div>
  </div>

  <button *ngIf="hideSeeMore" (click)="routeTo('courses')" class="learn-more">
    <span class="circle" aria-hidden="true">
      <span class="icon arrow"></span>
    </span>
    <span class="btn-text">See More Courses </span>
  </button>
</div>
