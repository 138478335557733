/* eslint-disable no-fallthrough */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from './services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'mpb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'mp';
  public isAppLoading$: Subscription = new Subscription();
  public isLoading$: Subscription = new Subscription();
  public showBanner$: Subscription = new Subscription();

  constructor(private appService: AppService, private router: Router, private DialogRef: MatDialog) {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          return this.appService.triggerLoader(true);
        }
        case event instanceof NavigationEnd: {
          return this.appService.triggerLoader(false);
        }
        default: {
          break;
        }
      }

      return;
    });

    // open(){
    //   this.DialogRef.open(ModalComponent);
    // }
  }

  ngOnInit(): void {
    this.appService.isLoading$.subscribe((loading) => {
      this.isAppLoading$ = loading || false;
    });

    this.appService.toastr.subscribe((msg) => {
      this.showBanner$ = msg.show;
    });
  }

  ngOnDestroy(): void {
    this.isAppLoading$.unsubscribe();
  }
}
