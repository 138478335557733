import { Component } from '@angular/core';

@Component({
  selector: 'mpb-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent {

}
