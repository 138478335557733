import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BootcampComponent } from './bootcamp/bootcamp.component';
import { ContactComponent } from './contact/contact.component';
import { CoursesComponent } from './courses/courses.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GraduationComponent } from './graduation/graduation.component';
import { HomeComponent } from './home/home.component';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { GradesComponent } from './grades/grades.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { MaterialComponent } from './material/material.component';
import { BootcampModalComponent } from './bootcamp/bootcamp-modal/bootcamp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

const routes: Routes = [
  { path: 'welcome', component: WelcomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'graduation-event', component: GraduationComponent },
  { path: 'bootcamp', component: BootcampComponent },
  { path: 'bootcamp-modal', component: BootcampModalComponent },
  { path: 'courses', component: CoursesComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'grades', component: GradesComponent},
  { path: 'assignments', component: AssignmentsComponent},
  { path: 'attendance', component: AttendanceComponent},
  { path: 'material', component:MaterialComponent},
  {
    path: 'login',
    component: LoginSignupComponent,
  },
  {
    path: '**',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'grades',
    component: GradesComponent,
  },
  {
    path: 'assignments',
    component: AssignmentsComponent,
  },
  {
    path: 'attendance',
    component: AttendanceComponent,
  },
  {
    path: 'material',
    component: MaterialComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: MatDialogRef,
      useValue: {}
    },
 ],
})
export class AppRoutingModule {}
