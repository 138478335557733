<div (click)="dismissModal()" class="more-info">
  <ng-container *ngIf="showInfo && !showPriceInfo && !showContactInfo">
    <div class="more-info_content">
      <svg
        (click)="dismissModal()"
        width="46"
        height="46"
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25Zm3.53 12.22a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 1.06-1.06L12 10.94l2.47-2.47a.75.75 0 0 1 1.06 1.06L13.06 12l2.47 2.47Z"
        ></path>
      </svg>
      <div class="more-info_content_lf">
        <div class="more-info_content_lf_fig">
          <video #coursesVid [src]="info?.vid"></video>
          <svg
            #coursesPlayIcon
            (click)="handlePlay(coursesVid, coursesPlayIcon)"
            width="46"
            height="46"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25Zm3.505 10.186-5.365 3.24a.506.506 0 0 1-.765-.435V8.76a.505.505 0 0 1 .765-.437l5.365 3.241a.51.51 0 0 1 0 .872Z"
            ></path>
          </svg>
        </div>
        <section class="more-info_content_lf_list">
          <div class="more-info_content_lf_list_title">Course highlights</div>
          <div
            *ngFor="let item of info.highlights"
            class="more-info_content_lf_list_features"
          >
            <svg
              width="46"
              height="46"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25Zm5.074 6.482-6.3 7.5a.748.748 0 0 1-.562.268h-.013a.75.75 0 0 1-.557-.248l-2.7-3a.751.751 0 0 1 .88-1.186c.09.045.17.107.234.182l2.123 2.36 5.747-6.84a.75.75 0 0 1 1.148.964Z"
              ></path>
            </svg>
            {{ item }}
          </div>
        </section>
      </div>
      <div class="more-info_content_description">
        <h3 class="more-info_content_description_title">Course:</h3>
        <span>{{ info.subTitle }}</span>
        <h5 class="more-info_content_description_title">Topic:</h5>
        <span>{{ info.title }}</span>
        <h3 class="more-info_content_description_title">Full Description:</h3>
        {{ info.moreInfo }}
        <h3 class="more-info_content_description_title">Price:</h3>
        <span>Coming Soon!!</span>
        <div class="more-info_content_description_btn">
          <button class="learn-more">
            <span class="circle" aria-hidden="true">
              <span class="icon arrow"></span>
            </span>
            <span (click)="routeTo('contact')" class="btn-text"
              >Contact Us</span
            >
          </button>
        </div>
      </div>
    </div>
  </ng-container>


</div>
