import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from '../services/app.service';

@Component({
  selector: 'mpb-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss'],
})
export class LoginSignupComponent implements OnInit {
  public loginForm: FormGroup = new FormGroup({});
  public response: any = {
    failure: (res: any) => {
      this.appService.showToast('Try Again!', 'error');
      console.log('Try Again');
    },
    success: (res: any) => {
      this.appService.setCookie('mpb-session', res?.session.jwt);
      this.appService.user$.next({
        isLoggedIn: true,
      });
      this.appService.showToast('Logged In!', 'success');
      this.loginForm.reset();
      this.appService.routeTo('/dashboard');
    },
  };
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
    });

    console.log('form from login', this.loginForm);
  }

  login() {
    this.appService
      .postData('/api/auth/login', {
        email: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value,
      })
      .subscribe((res: any) => {
        console.log('login', res);
        if (res.errors) this.response['failure'](res);
        if (!res.errors && res.session) this.response['success'](res);
      });
  }
}
