import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'mpb-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
})
export class MoreInfoComponent implements OnInit {
  @Input() info: any;
  @Input() showInfo = false;
  @Input() showPriceInfo = false;
  @Input() showContactInfo = false;
  @Input() showBootcampInfo = false;

  showValidationMsg = false;
  disableBtn = false;
  cardCaptureReady = false;
  cardDetailsFilledOut: any;
  invalidError = {
    message: '',
  };

  infoFormGroup = this._formBuilder.group({
    name: '',
    email: '',
    msg: '',
  });

  isLinear = false;

  constructor(
    private appService: AppService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    //
    console.log('more Info dialog', data)
    this.showBootcampInfo = data.showBootcampInfo
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.appService.isShowingModal$.subscribe((show) => {});
    this.appService.getData('api/')
    console.log('showBootcampInfo', this.info)
  }

  sendContact() {
    this.appService
      .postData('/api/contact', this.infoFormGroup.value)
      .subscribe((res: any) => {
        if (res.sentSuccessfully) {
          this.infoFormGroup.reset();
        }
      });
  }

  handlePlay(vid: any, icon: Element) {
    icon.remove();
    vid.play();
    vid.setAttribute('controls', 'controls');
  }

  handlePayment(bootcamp: any) {
    this.appService.triggerModal(false);
    // this.appService
    //   .postData('/api/payment', {})
    //   .subscribe((res: any) => {
    //     console.log('res from payments', res)
    //   });

    // if (bootcamp === 'frontend') {
    //   window.location.href = 'https://buy.stripe.com/9AQ3et1IjgVqbFm144';
    // } else {
    //   window.location.href = 'https://buy.stripe.com/7sIg1fev55cI9xe001';
    // }
  }

  handleContent() {
    const show = {
      showInfo: () =>
        this.showInfo && !this.showPriceInfo && !this.showContactInfo,
      showPriceInfo: () => this.showPriceInfo && !this.showContactInfo,
      showContactInfo: () => this.showContactInfo,
    };
  }

  handleNext() {
    const arr: string[] = [];
    // eslint-disable-next-line prefer-const
    Object.values(this.infoFormGroup.value).map((item, idx) => {
      if (item == '') {
        arr.push(item);
      }
    });

    if (arr.length > 2) {
      this.showValidationMsg = true;
      this.disableBtn = true;
    }
    this.disableBtn = false;
  }

  dismissModal() {
    this.appService.triggerModal(false);
    this.showBootcampInfo = false;
  }

  routeTo(r: any) {
    this.appService.routeTo(r);
  }
}
