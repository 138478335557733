<div class="bootcamp">
  <img class="bootcamp__img" [src]="camp?.imageUrl" alt="" />
  <mat-stepper class="bootcamp__stepper" #stepper [linear]="true"  [orientation]="orientation">
    <mat-step [stepControl]="bootcampForm">
      <form *ngIf="bootcampForm" [formGroup]="bootcampForm">
        <ng-template class="bootcamp__stepper_label" matStepLabel
          >Choose type for {{ camp?.type }} Bootcamp</ng-template
        >
        <mat-form-field>
          <h1 id="difficultyLevel" class="bootcamp__stepper_level_type">
            1. Choose Difficulty Level:
            <span *ngIf="!showLevelError" class="selectedType">{{ userSelectedDifficulty }}</span>
            <span *ngIf="showLevelError" class="selectedError">Please choose a difficulty level below to proceed!</span>
          </h1>
          <div class="bootcamp__stepper_level">
            <div
              *ngFor="let program of camp?.levels; index as i"
              class="bootcamp__stepper_level_card"
              (click)="handleLevel(program?.level)"
              required
              type="button"
              [id]="program?.level"
            >
              <span class="bootcamp__stepper_level_card_title">
                {{ program?.level }}
              </span>
              <div class="bootcamp__stepper_level_card_key">
                Key focus areas:
              </div>
              <span
                *ngIf="program?.level === 'Beginner'"
                class="bootcamp__stepper_level_card_areas"
              >
                <div *ngFor="let focus of camp?.focusAreas?.beginner">
                  <span>&#x2022;</span>
                  {{ focus }}
                </div>
              </span>
              <span
                *ngIf="program?.level === 'Intermediate'"
                class="bootcamp__stepper_level_card_areas"
              >
                <div *ngFor="let focus of camp?.focusAreas?.intermediate">
                  <span>&#x2022;</span>
                  {{ focus }}
                </div>
              </span>
              <span
                *ngIf="program?.level === 'Advanced'"
                class="bootcamp__stepper_level_card_areas"
              >
                <div *ngFor="let focus of camp?.focusAreas?.advanced">
                  <span>&#x2022;</span>
                  {{ focus }}
                </div>
              </span>
            </div>
          </div>

          <h1 id="bootcampType" class="bootcamp__stepper_bootcampType_title">
            2. Choose Bootcamp Type:
            <span *ngIf="!showTypeError" class="selectedType">{{ userSelectedCamp }}</span>
            <span *ngIf="showTypeError" class="selectedError">Please choose a bootcamp type below to proceed!</span>
          </h1>
          <div class="bootcamp__stepper_bootcampType">
            <div
              *ngFor="let selectedCamp of camp?.select; index as i"
              class="bootcamp__stepper_bootcampType_card"
              (click)="handleSelectedType(selectedCamp?.name)"
              required
              type="button"
              [id]="selectedCamp?.name"
            >
              <span class="bootcamp__stepper_bootcampType_card_title">
                {{ selectedCamp?.label }}
              </span>

              <span
                *ngIf="selectedCamp?.name === 'Frontend'"
                class="bootcamp__stepper_bootcampType_card_areas"
              >
                <div *ngFor="let focus of selectedCamp?.frontend">
                  <span>&#x2022;</span>
                  {{ focus }}
                </div>
              </span>

              <span
                *ngIf="selectedCamp?.name === '2-Month'"
                class="bootcamp__stepper_bootcampType_card_areas"
              >
                <div *ngFor="let focus of selectedCamp?.frontend">
                  <span>&#x2022;</span>
                  {{ focus }}
                </div>
              </span>

              <span
                *ngIf="selectedCamp?.name === 'Fullstack'"
                class="bootcamp__stepper_bootcampType_card_areas"
              >
                <div *ngFor="let focus of selectedCamp?.fullstack">
                  <span>&#x2022;</span>
                  {{ focus }}
                </div>
              </span>

              <span
                *ngIf="selectedCamp?.name === '3-Month'"
                class="bootcamp__stepper_bootcampType_card_areas"
              >
                <div *ngFor="let focus of selectedCamp?.fullstack">
                  <span>&#x2022;</span>
                  {{ focus }}
                </div>
              </span>
            </div>
          </div>
          <!-- See Details Below: -->
          <h1 class="bootcamp__stepper_level_type">
            <span class="selectedType">{{
              bootcampForm['controls']['bootcampLevel'].value
            }}</span>
            Bootcamp Details:
          </h1>
          <div class="bootcamp__content">
            <div class="bootcamp__content_top">
              <div class="bootcamp__content_top_lf">
                <div
                  class="bootcamp__content_top_lf_title"
                  [ngStyle]="{ color: camp?.color }"
                >
                  {{ camp?.title }}
                </div>
                <div class="bootcamp__content_top_lf_bullets">
                  <div
                    *ngFor="let bullet of camp?.bullets"
                    class="bootcamp__content_top_lf_bullets_crl"
                  >
                    {{ bullet }}
                    <span>&#x2022;</span>
                  </div>
                </div>
                <div class="bootcamp__content_top_lf_dots">
                  <div
                    *ngIf="
                      bootcampForm['controls']['bootcampLevel'].value ===
                      'Default'
                    "
                  >
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <span class="bootcamp__content_top_lf_dots_title">
                      Difficulty:
                      {{ bootcampForm['controls']['bootcampLevel'].value }}
                    </span>
                  </div>
                  <div
                    *ngIf="
                      bootcampForm['controls']['bootcampLevel'].value ===
                      'Beginner'
                    "
                  >
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleHalfStroke"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <span class="bootcamp__content_top_lf_dots_title">
                      Difficulty:
                      {{ bootcampForm['controls']['bootcampLevel'].value }}
                    </span>
                  </div>
                  <div
                    *ngIf="
                      bootcampForm['controls']['bootcampLevel'].value ===
                      'Intermediate'
                    "
                  >
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleHalfStroke"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <span class="bootcamp__content_top_lf_dots_title">
                      Difficulty:
                      {{ bootcampForm['controls']['bootcampLevel'].value }}
                    </span>
                  </div>
                  <div
                    *ngIf="
                      bootcampForm['controls']['bootcampLevel'].value ===
                      'Advanced'
                    "
                  >
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircle"></fa-icon>
                    <fa-icon class="dots" [icon]="faCircleNotch"></fa-icon>
                    <span class="bootcamp__content_top_lf_dots_title">
                      Difficulty:
                      {{ bootcampForm['controls']['bootcampLevel'].value }}
                    </span>
                  </div>
                </div>
                <div class="bootcamp__content_top_lf_pills">
                  <div
                    *ngFor="let pill of camp?.pills"
                    class="bootcamp__content_top_lf_pills_crl"
                  >
                    {{ pill }}
                  </div>
                </div>
                <div class="bootcamp__content_top_lf_desc">
                  {{ camp?.description }}
                </div>
              </div>
              <div class="bootcamp__content_top_rh">
                <h2>Tuition Information:</h2>
                <div class="bootcamp__content_top_rh_fe">
                  <div class="bootcamp__content_top_rh_fe_title">
                    {{ camp?.threeMonth?.name }} <span>(recommended)</span>
                  </div>
                  <div class="bootcamp__content_top_rh_fe_price">
                    <span class="bootcamp__content_top_rh_fe_price_p"
                      >Price:</span
                    >
                    <span class="bootcamp__content_top_rh_fe_price_t">{{
                      camp?.threeMonth?.price
                    }}</span>
                  </div>
                  <div class="bootcamp__content_top_rh_fe_duration">
                    <span class="bootcamp__content_top_rh_fe_duration_d"
                      >Duration:</span
                    >
                    <span class="bootcamp__content_top_rh_fe_duration_t">{{
                      camp?.threeMonth?.duration
                    }}</span>
                  </div>
                </div>

                <div class="bootcamp__content_top_rh_fs">
                  <div class="bootcamp__content_top_rh_fs_title">
                    {{ camp?.twoMonth?.name }} <span>(recommended)</span>
                  </div>
                  <div class="bootcamp__content_top_rh_fs_price">
                    <span class="bootcamp__content_top_rh_fs_price_p"
                      >Price:</span
                    >
                    <span class="bootcamp__content_top_rh_fs_price_t">{{
                      camp?.twoMonth?.price
                    }}</span>
                  </div>
                  <div class="bootcamp__content_top_rh_fs_duration">
                    <span class="bootcamp__content_top_rh_fs_duration_d"
                      >Duration:</span
                    >
                    <span class="bootcamp__content_top_rh_fs_duration_t">{{
                      camp?.twoMonth?.duration
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bootcamp__content_bottom">
              <div class="bootcamp__content_bottom_learn">
                <div class="bootcamp__content_bottom_learn_title">
                  What You'll Learn:
                </div>
                <div
                  *ngIf="camp?.whatYouLearn"
                  class="bootcamp__content_bottom_learn_items"
                >
                  <div
                    *ngFor="let item of camp?.whatYouLearn"
                    class="bootcamp__content_bottom_learn_items_item"
                  >
                    <fa-icon
                      *ngIf="item?.icon === 'faDesktop'"
                      class="WYLdots"
                      [ngStyle]="{ color: item?.color }"
                      [icon]="faDesktop"
                    ></fa-icon>
                    <fa-icon
                      *ngIf="item?.icon === 'faMobile'"
                      class="WYLdots"
                      [ngStyle]="{ color: item?.color }"
                      [icon]="faMobile"
                    ></fa-icon>
                    <fa-icon
                      *ngIf="item?.icon === 'faUncharted'"
                      class="WYLdots"
                      [ngStyle]="{ color: item?.color }"
                      [icon]="faUncharted"
                    ></fa-icon>
                    {{ item?.name }}
                  </div>
                </div>
                <div class="bootcamp__content_bottom_learn_instructors">
                  <span class="bootcamp__content_bottom_learn_title">
                    Instructors:
                  </span>
                  <div
                    class="bootcamp__content_bottom_learn_instructors_container"
                  >
                    <div
                      class="bootcamp__content_bottom_learn_instructors_img"
                      *ngFor="let instructor of camp?.instructors"
                    >
                      <img [src]="instructor?.profileImg" />
                      <span>
                        {{ instructor?.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bootcamp__content_bottom_build">
                <span class="bootcamp__content_bottom_learn_title">
                  What you'll build:
                </span>
                <div class="bootcamp__content_bottom_build_img">
                  <img [src]="camp?.whatYouBuild?.img" />
                  <span class="bootcamp__content_bottom_build_title">
                    {{ camp?.whatYouBuild?.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </mat-form-field>
        <div>
          <button (click)="handleNext(stepper)" class="learn-more">
            <span class="circle" aria-hidden="true">
              <span class="icon arrow"></span>
            </span>
            <span class="btn-text">Next </span>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="bootcampForm" label="Payment Options">
        <div *ngIf="!checkStep()" class="bootcamp__payment">
            <div (click)="handlePrevious(stepper)" class="bootcamp__payment_card-back">
                <span class="loader"></span>

                <div class="bootcamp__payment_card-back_areas">
                    Please Go To Previous Step and Make Proper Selections First!
                </div>
            </div>
        </div>
        <form *ngIf="checkStep()" [formGroup]="paymentForm">
        <mat-form-field>
          <h1 class="bootcamp__payment_title">3. Choose Payment Option:</h1>
          <div
            *ngIf="paymentInfo?.preRegister === true"
            class="bootcamp__payment"
          >
            <div
              (click)="handlePayment(paymentInfo?.preRegisterLink)"
              class="bootcamp__payment_card"
            >
              Pre-Register:
              <span class="bootcamp__payment_card_key"> One-Time Payment </span>
              <h1>
                {{ paymentInfo?.preRegisterPrice }}
              </h1>
              <span class="bootcamp__payment_card_areas">
                "This will secure your spot for the bootcamp class and allow you to embark on an exciting coding journey that will change your life!"
              </span>
              <span class="bootcamp__payment_card_pay">
                Click to proceed to secure payment page!
              </span>
            </div>
          </div>

          <div
            *ngIf="paymentInfo?.preRegister !== true"
            class="bootcamp__payment"
          >
            <div
              (click)="handlePayment(paymentInfo?.link)"
              class="bootcamp__payment_card"
            >
              Pay Full Tuition:
              <span class="bootcamp__payment_card_key"> One-Time Payment </span>
              <h1>
                {{ paymentInfo?.price }}
              </h1>
            </div>
            <h3>OR</h3>
            <div
              (click)="handlePayment(paymentInfo?.link)"
              class="bootcamp__payment_card"
            >
              Pay Monthly Tuition:
              <span class="bootcamp__payment_card_key"> 3 Total Payments </span>
              <h1>
                {{ paymentInfo?.paymentPrice }}
              </h1>
            </div>
          </div>
        </mat-form-field>
        <div>
          <button (click)="handlePrevious(stepper)" class="learn-more">
            <span class="circle" aria-hidden="true">
              <span
                class="icon arrow"
                [ngStyle]="{ transform: 'rotate(180deg)' }"
              ></span>
            </span>
            <span class="btn-text">Back </span>
          </button>
        </div>
      </form>
    </mat-step>
    <!-- <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p>You are now done.</p>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button (click)="stepper.reset()">Reset</button>
      </div>
    </mat-step> -->
  </mat-stepper>
</div>
